import { Routes } from '@angular/router';
import { securityGuardFn } from '@core/guards';


export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/registro/registro.routes').then(r => r.registroRoutes),
    title: 'Registro'
  },
  {
    path: 'sms',
    loadChildren: () => import('./features/sms/sms.routes').then(r => r.smsRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Sms'
  },
  {
    path: 'aviso-biometria',
    loadChildren: () => import('./features/biometricos/aviso-biometria/aviso-biometria.routes').then(r => r.avisoBiometriaRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Aviso de biometría'
  },
  {
    path: 'biometria',
    loadChildren: () => import('./features/biometricos/biometria/biometria.routes').then(r => r.biometriaRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Biometricos'
  },
  {
    path: 'cliente',
    loadChildren: () => import('./features/cliente/cliente.routes').then(r => r.clienteRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Formulario'
  },
  {
    path: 'email',
    loadChildren: () => import('./features/email/email/email.routes').then(r => r.emailRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Insertar email'
  },
  {
    path: 'email-confirma',
    loadChildren: () => import('./features/email/email-confirmar/email-confirmar.routes').then(r => r.emailConfirmarRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Confirmar email'
  },
  {
    path: 'nauphilus',
    loadChildren: () => import('./features/nauphilus/nauphilus.routes').then(r => r.nauphilusRoutes),
    canActivate: [ securityGuardFn ]
  },
  {
    path: 'sucursales',
    loadChildren: () => import('./features/sucursales/sucursales.routes').then( r => r.sucursalesRoutes ),
    canActivate: [ securityGuardFn ],
    title: 'Listado de sucursales'
  },
  {
    path: 'equipos',
    loadChildren: () => import('./features/equipos/equipos/equipos.routes').then(r => r.equiposRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Listado de equipos'
  },
  {
    path: 'detalle-producto',
    loadChildren: () => import('./features/equipos/detalle-producto/detalle-producto.routes').then(r => r.detalleProductoRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Detalle del equipo'
  },
  {
    path: 'direccion',
    loadChildren: () => import('./features/entrega-equipo/direccion-entrega.routes').then(r => r.direccionEntregaRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Entrega a domicilio'
  },
  {
    path: 'seleccionar-pago',
    loadChildren: () => import('./features/pago-oxxo/pago-oxxo.routes').then(r => r.pagoOxxoRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Seleccionar método de pago'
  },
  {
    path: 'pago',
    loadChildren: () => import('./features/pago-tarjeta/pago-tarjeta.routes').then(r => r.pagoTarjetaRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Pago con tarjeta'
  },
  {
    path: 'entrega',
    loadChildren: () => import('./features/finalizar-proceso/finalizar-proceso.routes').then(r => r.finalizarProcesoRoutes),
    canActivate: [securityGuardFn  ],
    title: 'Finalizar proceso'
  },
  {
    path: 'ingresa',
    loadChildren: () => import('./features/retomar-tramite/retomar-tramite.routes').then(r => r.retomarTramiteRoutes),
    canActivate: [ securityGuardFn ],
    title: 'Retomar trámite'
  },
  {
    path: 'aviso-cero-enganche',
    loadChildren: () => import('./features/enganche-cero/aviso-enganche-cero.routes').then(r => r.avisoEngancheCeroRoutes),
    canActivate: [securityGuardFn ],
    title: 'Enganche cero'
  },
  {
    path: 'region-inactiva',
    loadChildren: () => import('./features/region-inactiva-smt/region-inactiva-smt.routes').then(r => r.regionInactivaSmtRoutes),
    canActivate: [securityGuardFn ],
    title: 'Región inactiva'
  },
  { path: '**', redirectTo: '' },

];
