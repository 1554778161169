import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { TokenService } from '@core/services';


export const securityGuardFn: CanActivateFn = () => {
  const tokenService = inject(TokenService);
  const router = inject(Router);

  return !tokenService.isValidToken() ? router.createUrlTree(['']) : true;
}
