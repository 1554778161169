@if (isDesktop && !allowDesktopUse()) {
  <div class="h-screen flex flex-column justify-content-center align-items-center color-primary"
>
    <h1 class="text-center">Dispositivo no válido</h1>
    <p>Por favor haga el proceso desde un dispositivo móvil.</p>
  </div>
} @else {
  @if (showOutlet()) {
    <router-outlet></router-outlet>
  }
  @else {
    <div class="h-screen flex flex-column justify-content-center align-items-center color-primary">
      <h1 class="text-center">URL no válida</h1>
      <p>Por favor contacte a un empleado que le devuelva una url válida</p>
    </div>
  }
}

<ngx-ui-loader
  [text]="loadingText()"
  [textPosition]="'center-center'"
></ngx-ui-loader>
