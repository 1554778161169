import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, finalize, tap } from 'rxjs';

import { GlobalVarService } from '@core/services';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private readonly ngxService = inject(NgxUiLoaderService);
  private readonly router = inject(Router);
  private readonly globalVarService = inject(GlobalVarService);

  private activeRequests = 0;
  private isMotorCalculoRequest = false;


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const clonedRequest = request.clone();

    if (!this.isMotorCalculoRequest || !clonedRequest.url.includes('motorCalculo')) {
      this.activeRequests++;
      this.ngxService.start();
    }

    this.isMotorCalculoRequest = clonedRequest.url.includes('motorCalculo');

    return next.handle(clonedRequest).pipe(
      tap((response: any) => {
        if (response?.body?.process === false) {
          const message = (response?.body?.message as string)?.toLocaleLowerCase();
          if (message?.includes('jwt')) {
            this.globalVarService.warningToastText.set(message);
            this.router.navigateByUrl(sessionStorage.getItem('queryParams') || '/')
          }
        }
      }),
      finalize(() => {
        this.activeRequests && this.activeRequests--;

        if (!this.activeRequests) {
          this.ngxService.stop();
        }
      })
    );
  }
}
