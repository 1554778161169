import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';

import { TokenService } from '../services';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshInProgressToken = false;

  constructor(private tokenService: TokenService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      finalize(async () => {
        if (this.canIRefreshToken(request) && !this.refreshInProgressToken) {
          this.refreshInProgressToken = true;
          await this.tokenService.refreshToken().subscribe({
            next: (response) => {
              this.refreshInProgressToken = false;
              sessionStorage.setItem('tokenTelcel', response);
            },
            error: () => {
              this.refreshInProgressToken = false;
            }
          })
        }
      })
    );
  }

  private canIRefreshToken(request: HttpRequest<unknown>): boolean {
    const filteredRequest = [
      'getAccessTokenVeridas',
      'sendOTPVerificacion',
      'sendDatosCliente',
      // 'cacs',
      // 'motorCalculo',
      'direccion'
    ];

    const urlRequest = request.url;

    for (let i = 0; i < filteredRequest.length; i++) {
      if (urlRequest.includes(filteredRequest[i])) {
        return true;
      }
    }

    return false;
  }
}
