
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, computed, inject } from '@angular/core';
import { ActivatedRoute, Params, RouterOutlet } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { GlobalVarService } from '@core/services';
/**
 * Componenten principal donde se aloja el router-outlet
 ** Creado por: Jesus Martínez Torres
 ** Fecha: 10/04/2023
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    NgxUiLoaderModule
  ],
})
export class AppComponent implements OnInit {
  private readonly globalVarService = inject(GlobalVarService);
  private readonly deviceService = inject(DeviceDetectorService);
  private readonly activatedRouter = inject(ActivatedRoute);
  private readonly cdr = inject(ChangeDetectorRef);

  isDesktop: boolean = false;
  allowDesktopUse = computed(() => this.globalVarService.isPhone());
  showOutlet = computed(() => this.globalVarService.showOutlet());
  loadingText = computed(() => this.globalVarService.loadingText());


  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe({
      next: (response) => this.checkDesktopUse(response)
    });
  }

  checkDesktopUse(response: Params) {
    /**
     * Este código debe mejorarse ya que utilizar el setTimeOut se le considera una mala práctica.
     * Como el app.component existe a lo largo del flujo por ser el principal, por eso se tiene que recuperar primero la variable de sessión llamada 'desktop' antes que el layout del registro antes de que elimine todo el sessionStorage.
    **/
    setTimeout(() => {
      this.globalVarService.isPhone.set(sessionStorage.getItem('desktop') ? true : false);
      const { desktop } = response;

      if (!this.allowDesktopUse()) {
        this.globalVarService.isPhone.set(desktop !== undefined);
        this.allowDesktopUse() && sessionStorage.setItem('desktop', String(this.allowDesktopUse()));
      }
    }, 100);
  }
}
